import React from "react"
import SEO from "../../components/CommonComponents/SEO"
import Layout from "../../components/CommonComponents/Layout"
import UiUxHeader from "../../components/UiUxDesign/UiUxHeader"
import UiUxAdvantages from "../../components/UiUxDesign/UiUxAdvantages"
import UiUxUserExperienceInterface from "../../components/UiUxDesign/UiUxUserExperienceInterface"
import UiUxLatestProjects from "../../components/UiUxDesign/uiUxLatestProject"
import ContactUsExpertise from "../../components/CommonComponents/ContactUsExpertise"
import { mainUrl } from "../../js/config"

export default function UiUxDesign() {
  const sendUrl = typeof window !== "undefined" ? window?.location?.href : ""
  return (
    <div>
      <SEO
        title="UX/UI Design Services for Your Business"
        description="A good UX/UI Design for your project can help expand your business. OS-System developers offer UI/UX design services to ensure the best quality for your startup."
        canonical={`${mainUrl}/services/uiuxdesign/`}
      />
      <Layout showFormPoint={100}>
        <UiUxHeader />
        <UiUxAdvantages />
        <UiUxUserExperienceInterface />
        <UiUxLatestProjects />
        <ContactUsExpertise form={0} url={sendUrl} />
      </Layout>
    </div>
  )
}
